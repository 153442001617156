import { useEffect, useState } from 'react'

import Head from 'next/head'
import Page from '../app/hoc/defaultPage'
import dynamic from 'next/dynamic'
import CircularProgress from '../app/components/CircularProgress'
import { getSettings } from '../utils/session'

const SignIn = dynamic(() => import('../routes/auth/signIn'), {
  loading: () => <CircularProgress />,
})

const Signin = () => {
  const [settings, setSettings] = useState({})

  useEffect(() => {
    if(typeof window != 'undefined'){
       setSettings(getSettings())
    }
  }, []);

  return (
    <>
      <Head>
        <title> {settings['app.name']} | Login</title>
      </Head>
      <SignIn />
    </>
  )
}

export default Page(Signin)
